import React, { useState } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const words = [
    "New home coming",
    "Hobby projects, creations and more",
    "Stay tuned",
  ]
  const colors = ["#bd5926", "#ba8972", "white"]
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentColorIndex, setCurrentColorIndex] = useState(0);

  const handleAnimationIterationEnd = (e) => {
    setCurrentWordIndex(currentWordIndex + 1)
    if (currentWordIndex === words.length) {
      setCurrentWordIndex(0)
    }

    setCurrentColorIndex(currentColorIndex + 1)
    if (currentColorIndex === colors.length) {
      setCurrentColorIndex(0)
    }
  }

  return (
    <Layout>
      <Seo title="Home" />
        <h1 className="console-container">
          <span id="text" className="text" style={{color: colors[currentColorIndex]}} onAnimationIteration={handleAnimationIterationEnd}>{words[currentWordIndex]}&nbsp;</span>
          <span className="console-underscore">&nbsp;&nbsp;</span>
        </h1>
    </Layout>
  )
}

export default IndexPage
